const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://knowledgebase.murall.art',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://murall.art/images/murall_top_logo.svg',
    logoLink: '/',
    title: "",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="https://twitter.com/MurAll_art" target="_blank" rel="noopener">
		      <div class="socialBtn">
		        <img src='/icon-twitter.jpeg' alt={'Twitter'}/>
		      </div>
		    </a>
		  </li>
			<li>
		    <a href="https://discord.gg/murall" target="_blank" rel="noopener">
		      <div class="socialBtn">
		        <img src='/icon-discord.jpeg' alt={'Discord'}/>
		      </div>
		    </a>
		  </li>
      <li>
		    <a href="https://www.instagram.com/murall.art" target="_blank" rel="noopener">
		      <div class="socialBtn">
		        <img src='/icon-instagram.jpeg' alt={'Instagram'}/>
		      </div>
		    </a>
		  </li>
      <li>
		    <a href="https://t.me/MurALLart" target="_blank" rel="noopener">
		      <div class="socialBtn">
		        <img src='/icon-telegram.jpeg' alt={'Telegram'}/>
		      </div>
		    </a>
		  </li>
      <li>
		    <a href="https://www.youtube.com/channel/UCrEFkjpHY5YojV4px7kGcZQ" target="_blank" rel="noopener">
		      <div class="socialBtn">
		        <img src='/icon-youtube.jpeg' alt={'Youtube'}/>
		      </div>
		    </a>
		  </li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'knowledgebase',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/canvas',
      '/paint',
      '/murall',
      '/montage',
      '/tutorials'
    ],
    collapsedNav: [
      '/tutorials/minting'
    ],
    links: [
      { text: 'Visit MurAll', link: 'https://murall.art' },
      { text: 'Litepaper', link: 'https://murall.art/docs/LitePaper.pdf' }
    ],
    frontline: true,
    ignoreIndex: true,
    title: "",
  },
  siteMetadata: {
    title: 'Knowledge Base | MurAll',
    description: 'MurAll knowledge base. Everything you need to know about MurAll project, minting NFTs and the Paint token',
    ogImage: 'https://murall.art/images/MurAll_Logo_L1.png',
    docsLocation: '',
    favicon: 'https://murall.art/images/favicon.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'MurAll Knowledge Base',
      short_name: 'MurAllKnowledgeBase',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
